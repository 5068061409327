.tooltip-dark,
.tooltip-light {
  @apply !max-w-[300px];
  .explorer-tooltip-inner {
    box-shadow:
      0 4px 6px -1px rgb(0 0 0 / 10%),
      0 2px 4px -2px rgb(0 0 0 / 10%) !important;
    @apply text-foreground border-border border text-sm px-3 py-[6px] min-h-[32px] rounded-md !important;
  }
}
.tooltip-light {
  .explorer-tooltip-inner {
    @apply !text-foreground;
  }
}
